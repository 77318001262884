import { ArrowBackIosNewOutlined, VideoFileRounded } from "@mui/icons-material";
import React, { useState } from "react";
import "./watch.scss";
import { AzureMP } from "react-azure-mp";
import { useNavigate, useLocation , Link} from "react-router-dom";
import { useContext } from "react";
import { Contexts } from "../../../contexts/Contexts";
import axios from "axios";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { PUBLIC_URL } from "./../../../ELearning/Components/Common/Constants.js";

const Watch = () => {
  const location = useLocation();
  let videosource = location.state.videourl ? location.state.videourl.toString() : "";
  const corporateId = location.state.corporateId;
  const podcastId = location.state.podcastId;
  const timestamp = location.state.timestamp;
  const playbackFinished = location.state.playbackCompleted;

  if (location.moreFlag) {
    location.setmoreFlag(false);
  }

  let { duration, playbackCompleted } = useContext(Contexts);
  const config = {
    headers: { Authorization: `Bearer ${sessionStorage.token}` },
  };

  let navigate = useNavigate();
  const routeChange = () => {
    let path = `/videochannel`;
    navigate(path);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      axios.post(
        PUBLIC_URL + `/api/Podcast/UserVideoMapping`,
        {
          corpID: corporateId,
          podcastID: podcastId,
          userID: Number(sessionStorage.getItem("userId")),
          playBackRemaining: duration,
          videoCompleted: playbackCompleted,
        },
        config
      ).catch((error) => {
        console.log(error);
      });
    }, 10000);

    return () => clearInterval(interval);
  }, [duration, playbackCompleted, corporateId, podcastId, config]);

  const onInstanceCreated = (player) => {
    if (player) {
      let initialSeekDone = false;

      player.on("play", () => {
        if (!initialSeekDone && Number(timestamp) !== 0 && !playbackFinished) {
          player.currentTime(timestamp);
          initialSeekDone = true;
        }
      });

      player.on("timeupdate", () => {
        duration = Math.round(player.currentTime());
      });

      player.on("ended", () => {
        playbackCompleted = 1;
      });
    }
  };

  const { t } = useTranslation();

  const forwardVideo = (player) => {
    const newTime = player.currentTime() + 5;
    player.currentTime(newTime);
  
  };

  
  const rewindVideo = (player) => {
    const newTime = player.currentTime() - 5;
    player.currentTime(newTime);
    
  };

  
  const handleVideoClick = (e, player) => {
    const videoWidth = e.target.offsetWidth;
    const clickPosition = e.clientX - e.target.getBoundingClientRect().left;
    if (clickPosition < videoWidth / 2) {
      rewindVideo(player);
     
    } else { 
      forwardVideo(player);
     
    }  
  };
  

  if (videosource) {
    return (
      <div className="watch">
        <div className="back">
          <Link style={{ color: "white" }} reloadDocument to="/videochannel">
            {t("rbkey_BckLBL")}
          </Link>
        </div>
        <AzureMP
          onInstanceCreated={onInstanceCreated}
          className="VideoPlayer"
          adaptRatio={[16, 9]}
          skin="amp-flush"
          src={[{ src: videosource }]}
          options={{
            autoplay: true,
            controls: true,
            width: "600",
            height: "400",
            playbackSpeed: {
              enabled: true,
              initialSpeed: 1.0,
              speedLevels: [
                  { name: "x4.0", value: 4.0 },
                  { name: "x3.0", value: 3.0 },
                  { name: "x2.0", value: 2.0 },
                  { name: "x1.75", value: 1.75 },
                  { name: "x1.5", value: 1.5 },
                  { name: "x1.25", value: 1.25 },
                  { name: "normal", value: 1.0 },
                  { name: "x0.75", value: 0.75 },
                  { name: "x0.5", value: 0.5 },
              ]
          },
          }}
         
          onClick={(e) => handleVideoClick(e, e.target)}
        />
      </div>
    );
  } else {
    return (
      <p style={{ display: "flex", justifyContent: "center", padding: "2% 0 0 0" }}>
        No URL Found
      </p>
    );
  }
};

export default Watch;