import React, { useState, useEffect } from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import "./ML_Videochannel.css";
import { DefaultPlayer as Video } from "react-html5video";
import "react-html5video/dist/styles.css";
import { useNavigate } from "react-router-dom";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import axios from "axios";
import { Audio } from "react-loader-spinner";
import { useTranslation } from "react-i18next";
import {
  PUBLIC_URL,
  CONTAINER_NAME,
  STORAGE_ACCOUNT_NAME,
  SAS_TOKEN,
  DOMAIN_ID,
} from "./../Common/Constants.js";
import NotFound from "../../Assets/Components/NotFound";
import feedbackIcon from "../../Assets/Images/feedbackIcon.jpg";

export const ML_ClassroomTraining = (props) => {
  var nominationStatus;
  const [posts, setposts] = useState([]);
  const [postsSave, setPostsSave] = useState([]);
  const { t } = useTranslation();
  const [postsData, setPostsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const navigate = useNavigate();
  var noOfRecords;
  function removeTags(str) {
    if (str === null || str === "" || str === undefined) return "NA";
    else str = str.toString();

    // Regular expression to identify HTML tags in
    // the input string. Replacing the identified
    // HTML tag with a null string.
    return str.replace(/(<([^>]+)>)/gi, "  ");
  }
  const filteredPosts = posts.filter((item, key) => {
    if (item.VC_ILT_CRS_LNCH_NM != null && item.VC_ILT_CD != null) {
      if (item.VC_ILT_CRS_LNCH_NM == item.VC_ILT_CD) {
        //Added by Saurav
        return item.VC_ILT_CRS_LNCH_NM.toLowerCase().includes(
          String(props.search).toLowerCase()
        );
      } else {
        return (
          item.VC_ILT_CRS_LNCH_NM.toLowerCase().includes(
            String(props.search).toLowerCase()
          ) ||
          item.VC_ILT_CD.toLowerCase().includes(
            String(props.search).toLowerCase()
          )
        );
      }
    }
    //
  });

  useEffect(() => {
    console.log("POST SAVE : ", postsSave);
  }, [postsSave]);

  useEffect(() => {
    axios
      .get(PUBLIC_URL + "/api/ClassRoomTraining/MyLearning/ILT", {
        params: {
          searchType: "",
          searchText: "",
          orderBy: "DT_STRT_DT",
          order: "DESC",
          pageNumber: 1,
          numberOfRecordsPerPage: 100,
          corporateId:
            sessionStorage.getItem("userDomain") !== null
              ? sessionStorage.getItem("userDomain")
              : DOMAIN_ID,
          userId: sessionStorage.getItem("userId"),
          combineAssignedCourses: "",
          learningType: "",
          sessionId: "en-US",
          rbKeyValueProgam: "Program",
          rbKeyValueCourse: "eLearning",
          rbKeyValueILT: "Classroom Training",
          iltId: "",
          courseLaunchId: "",
        },
      })
      .then((res) => {
        setposts(
          res.data.filter((datas) => {
            return datas.NMNTN_STTS !== "X";
          })
        );
        setPostsSave(
          res.data.filter((datas) => {
            return datas.NMNTN_STTS !== "X";
          })
        );
        setLoaded(true);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    infinite: false,
  };
  const settingsShowMore = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "60px",
    slidesToShow: 3,
    speed: 500,
    rows: 2,
    slidesPerRow: 2,
  };
  if (posts.length === 0 && !loaded) {
    return (
      <div
        style={{
          textAlign: "center",
          fontWeight: "500",
          fontSize: "24px",
        }}
      >
        <div style={{ paddingLeft: "45%" }}>
          <Audio height="60" width="75" color="grey" arialLabel="Loading" />
        </div>
      </div>
    );
  } else if (posts.length === 0 && loaded) {
    return <NotFound />;
  }
  function handleClickShowMore() {
    setShowMore(!showMore);
    if (showMore === true) {
      noOfRecords = 100;
    } else {
      noOfRecords = 12;

      axios
        .get(PUBLIC_URL + "/api/ClassRoomTraining/MyLearning/ILT", {
          params: {
            searchType: "",
            searchText: "",
            orderBy: "VC_ILT_CRS_LNCH_NM",
            order: "ASC",
            pageNumber: 1,
            numberOfRecordsPerPage: noOfRecords,
            corporateId:
              sessionStorage.getItem("userDomain") !== null
                ? sessionStorage.getItem("userDomain")
                : DOMAIN_ID,
            userId: sessionStorage.getItem("userId"),
            combineAssignedCourses: "",
            learningType: "",
            sessionId: "en-US",
            rbKeyValueProgam: "Program",
            rbKeyValueCourse: "eLearning",
            rbKeyValueILT: "Classroom Training",
            iltId: "",
            courseLaunchId: "",
          },
        })
        .then((res) => {
          setPostsData(res.data);
          setLoaded(true);
        })
        .catch((err) => {
          console.log(err);
        });
        
      setLoaded(true);
    }
  }

  const feedbackClickHandler = (e) => {
    debugger;
    navigate("/feedback", {
      state: {
        iltId: e.target.id,
      },
    });
  };

  if (props.search == "" || props.search == null) {
    return (
      <div
        style={{
          margin: "30px",
          backgroundColor: "#111f3b",
          marginBottom: "0px",
        }}
      >
        <Slider {...settings}>
          {postsSave.map((item, index) => {
            if ((item.NMNTN_STTS = "C")) {
              nominationStatus = "Completed";
            } else if ((item.NMNTN_STTS = "P")) {
              nominationStatus = "Pending";
            } else if ((item.NMNTN_STTS = "S")) {
              nominationStatus = "Started";
            } else {
              nominationStatus = "Pending";
            }

            const sasToken = SAS_TOKEN;
            const containerName = CONTAINER_NAME;
            const storageAccountName = STORAGE_ACCOUNT_NAME;
            let tempURL;
            if (item.VC_UPLDD_FL_NM != null) {
              tempURL =
                `https://${storageAccountName}.blob.core.windows.net/${containerName}/WCR/WCRILTDirectory/${item.NM_ILT_CRS_ID}/${item.VC_UPLDD_FL_NM}?` +
                sasToken;
            } else {
              tempURL =
                "https://cdn.pixabay.com/photo/2018/09/15/13/28/trainer-3679422__480.jpg";
            }
            //
            let stringForDescription = removeTags(item.VC_ILT_CRS_LNCH_DSCRPTN);
            //
            return (
              <div className="wrapper" key={index}>
                <Link
                  className="link"
                  to="/detailsML"
                  state={{
                    course_id: item.NM_ILT_CRS_ID,
                    type: item.NM_LRNNG_TYP,
                   // description: stringForDescription,
                   description:item.VC_ILT_CRS_LNCH_DSCRPTN,
                    certificateID: item.NM_CRTFCT_ID,
                    imgSrc: tempURL,
                    VC_ILT_CD: item.VC_ILT_CD,
                    topic_name: item.VC_ILT_KND_NM,
                    iltId: item.NM_ILT_CRS_ID,
                  }}
                >
                  <img
                    style={{ width: "100%", height: "125px",objectFit: "cover" }}
                    src={tempURL}
                    alt=""
                  />
                </Link>
                <p
                  style={{
                    color: "white",
                    fontWeight: "bold",
                    fontSize: "smaller",
                    marginBottom: 0,
                  }}
                >
                  <ProgressBar
                    now={item.NM_PRGRSS}
                    label={`${item.NM_PRGRSS}%`}
                    className="progressBar"
                  />
                </p>
                {item.BT_FDBCK_RQRD &&
                  item.BT_FDBCK_ILT_LVL &&
                  !item.BT_BLNDD &&
                  item.CH_ILT_STTS == "X" &&
                  item.NM_FRM_ID != undefined &&
                  item.NM_FRM_ID > 0 && (
                    <img
                      id={item.NM_ILT_CRS_ID}
                      src={feedbackIcon}
                      alt="feedback"
                      style={{
                        height: "20px",
                        width: "20px",
                        margin: "6px 0 0 1px",
                        cursor: "pointer",
                      }}
                      onClick={(e) => {
                        feedbackClickHandler(e);
                      }}
                    />
                  )}

                {/* <div className="title" style={{ display: "flex" }}>
                  <p title={item.VC_ILT_CRS_LNCH_NM}>
                    {item.VC_ILT_CRS_LNCH_NM?.substring(0, 11)}...
                  </p>{" "}
                  |{" "}
                  <p title={item.VC_ILT_CD}>
                    {item.VC_ILT_CD?.substring(0, 11)}...
                  </p>
                </div> */}

                  <div className="title" style={{ display: "flex" }}>
                     <p title={item.VC_ILT_CRS_LNCH_NM}>
                      {item.VC_ILT_CRS_LNCH_NM?.substring(0, 30)}
                      {item.VC_ILT_CRS_LNCH_NM?.length > 30 ? "..." : ""}
                     </p>{" "}
                    </div>

                <p className="description">
                  {stringForDescription.toString().substring(0, 40)}
                  <Link
                    className="directlink"
                    to="/detailsML"
                    state={{
                      course_id: item.NM_ILT_CRS_ID,
                      type: item.NM_LRNNG_TYP,
                     // description: stringForDescription,
                     description:item.VC_ILT_CRS_LNCH_DSCRPTN,
                      certificateID: item.NM_CRTFCT_ID,
                      imgSrc: tempURL, //Added by Saurav
                      VC_ILT_CD: item.VC_ILT_CD,
                      topic_name: item.VC_ILT_KND_NM,
                      iltId: item.NM_ILT_CRS_ID,
                    }}
                  >
                    ...
                    {t("rbkey_Showmore_LBL")}
                  </Link>
                </p>
              </div>
            );
          })}
        </Slider>

        <div
          style={{
            textAlign: "right",
            paddingRight: "5px",
            fontWeight: "bold",
            color: "white",
          }}
        >
          <button
            style={{
              color: "White",
              border: "none",
              borderRadius: "5px",
              backgroundColor: "rgb(78, 78, 78)",
              fontSize: "small",
            }}
            onClick={() => {
              navigate("/mylearning/classroomtraining");
            }}
          >
            {t("rbkey_VwAllLBL")}
          </button>
        </div>
      </div>
    );
  }

  if (!filteredPosts?.length) {
    return <NotFound />;
  }

  return (
    <div
      style={{
        margin: "30px",
        backgroundColor: "#111f3b",
        marginBottom: "0px",
      }}
    >
      <Slider {...settings}>
        {filteredPosts.map((item, index) => {
          if ((item.NMNTN_STTS = "C")) {
            nominationStatus = "Completed";
          } else if ((item.NMNTN_STTS = "P")) {
            nominationStatus = "Pending";
          } else if ((item.NMNTN_STTS = "S")) {
            nominationStatus = "Started";
          } else {
            nominationStatus = "Pending";
          }

          const sasToken = SAS_TOKEN;
          const containerName = CONTAINER_NAME;
          const storageAccountName = STORAGE_ACCOUNT_NAME;
          let tempURL; //Added by Saurav
          if (item.VC_UPLDD_FL_NM != null) {
            tempURL =
              `https://${storageAccountName}.blob.core.windows.net/${containerName}/WCR/WCRILTDirectory/${item.NM_ILT_CRS_ID}/${item.VC_UPLDD_FL_NM}?` +
              sasToken;
          } else {
            tempURL =
              "https://cdn.pixabay.com/photo/2018/09/15/13/28/trainer-3679422__480.jpg";
          }
          //
          let stringForDescription = removeTags(item.VC_ILT_CRS_LNCH_DSCRPTN);
          //
          return (
            <div className="wrapper" key={index}>
              <Link
                className="link"
                to="/detailsML"
                state={{
                  course_id: item.NM_ILT_CRS_ID,
                  type: item.NM_LRNNG_TYP,
                  //description: stringForDescription,
                  description:item.VC_ILT_CRS_LNCH_DSCRPTN,
                  certificateID: item.NM_CRTFCT_ID,
                  imgSrc: tempURL, //Added by Saurav
                  VC_ILT_CD: item.VC_ILT_CD,
                  topic_name: item.VC_ILT_KND_NM,
                  iltId: item.NM_ILT_CRS_ID,
                }}
              >
                <img
                  style={{ width: "100%", height: "125px",objectFit: "cover" }}
                  src={tempURL}
                  alt=""
                />
              </Link>
              <p
                style={{
                  color: "white",
                  fontWeight: "bold",
                  fontSize: "smaller",
                  marginBottom: 0,
                }}
              >
                <ProgressBar
                  now={item.NM_PRGRSS}
                  label={`${item.NM_PRGRSS}%`}
                  className="progressBar"
                />
              </p>

              {item.BT_FDBCK_RQRD &&
                item.BT_FDBCK_ILT_LVL &&
                !item.BT_BLNDD &&
                item.CH_ILT_STTS == "X" &&
                item.NM_FRM_ID != undefined &&
                item.NM_FRM_ID > 0 && (
                  <img
                    id={item.NM_ILT_CRS_ID}
                    src={feedbackIcon}
                    alt="feedback"
                    style={{
                      height: "20px",
                      width: "20px",
                      margin: "6px 0 0 1px",
                      cursor: "pointer",
                    }}
                    onClick={(e) => {
                      feedbackClickHandler(e);
                    }}
                  />
                )}

              {/* <div className="title" style={{ display: "flex" }}>
                <p title={item.VC_ILT_CRS_LNCH_NM}>
                  Gaurav{item.VC_ILT_CRS_LNCH_NM?.substring(0, 11)}...
                </p>{" "}
                |{" "}
                <p title={item.VC_ILT_CD}>
                  {item.VC_ILT_CD?.substring(0, 11)}...
                </p>
              </div> */}
               <div className="title" style={{ display: "flex" }}>
                     <p title={item.VC_ILT_CRS_LNCH_NM}>
                      {item.VC_ILT_CRS_LNCH_NM?.substring(0, 30)}
                      {item.VC_ILT_CRS_LNCH_NM?.length > 30 ? "..." : ""}
                     </p>{" "}
                    </div>

              <p className="description">
                {stringForDescription.toString().substring(0, 40)}
                <Link
                  className="directlink"
                  to="/detailsML"
                  state={{
                    course_id: item.NM_ILT_CRS_ID,
                    type: item.NM_LRNNG_TYP,
                   // description: stringForDescription,
                   description:item.VC_ILT_CRS_LNCH_DSCRPTN,
                    certificateID: item.NM_CRTFCT_ID,
                    imgSrc: tempURL, //Added by Saurav
                    VC_ILT_CD: item.VC_ILT_CD,
                    topic_name: item.VC_ILT_KND_NM,
                    iltId: item.NM_ILT_CRS_ID,
                  }}
                >
                  ...
                  {t("rbkey_Showmore_LBL")}
                </Link>
              </p>
            </div>
          );
        })}
      </Slider>

      <div
        style={{
          textAlign: "right",
          paddingRight: "5px",
          fontWeight: "bold",
          color: "white",
        }}
      >
        <button
          style={{
            color: "White",
            border: "none",
            borderRadius: "5px",
            backgroundColor: "rgb(78, 78, 78)",
            fontSize: "small",
          }}
          onClick={() => {
            navigate("/mylearning/classroomtraining");
          }}
        >
          {t("rbkey_VwAllLBL")}
        </button>
      </div>
    </div>
  );
};
